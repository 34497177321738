export default {
  csrf: (state) => state.csrf,
  token: (state) => state.token,
  user: (state) => state.user,
  isRoot: (state, getters) => getters.user && getters.user.persona.es_root,
  full_name(state, getters) {
    return (getters.user && getters.user.persona.nombre_completo) || "";
  },
  imagen_url: (state, getters) => getters.user && getters.user.persona.imagen_url
};
