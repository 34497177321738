<template>
  <div>
    <div ref="chart"></div>
    <p v-if="loading" class="p-4">
      <b-skeleton active></b-skeleton>
      <b-skeleton height="120px"></b-skeleton>
    </p>
  </div>
</template>
<script>
import {GoogleCharts} from 'google-charts';
import { parseDate,isNumeric } from '@/utils/helpers';
export default {
  props: {
    results:{
      type: Array,
      default: () => []
    },
    fullHeight: {
      type: Boolean,
      default: () => false
    },
    selection: {
      type: Object,
      default: () => null,
    }
  },
  data(){
    return {
      loading: true,
      isDate: false,
    }
  },
  computed:{
    rows(){
      return this.results.map(e => {
        if(typeof(e[0]) === "object" && e[0].type === "date") this.isDate = true
        if(typeof(e[0]) === "string" && this.isDate) e[0] = parseDate(e[0],"-")
        return e
      }) 
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        if(!this.$refs.chart) {
          this.loading = false
          return;
        }
        GoogleCharts.load(this.drawChart)
        this.loading = false
      })
    }, 500)
  },
  methods: {
    drawChart(){
      const data = GoogleCharts.api.visualization.arrayToDataTable(this.rows);
      const view = new GoogleCharts.api.visualization.DataView(data);
      let index_fila = null
      let index_columna = null
      if(this.rows.length) {
        const [a = [],...b] = this.rows
        const {fila,columna} = this.selection || {}
        const items = []
        for (let index = 0; index < a.length; index++) {
          items.push(index)
          if(index > 0){
            items.push({
              calc: "stringify",
              sourceColumn: index,
              type: "string",
              role: "annotation"
            })
            if(columna && a[index].label === columna) {
              index_columna = items.length - 1
            }
          }
        }
        view.setColumns(items)
        for (const e of b) {
          for (let index = 0; index < e.length; index++) {
            if(fila && index === 0 && e[index] === fila){
              index_fila = index;
            }
          }
        }
        if(isNumeric(index_fila) && !isNumeric(index_columna)) index_columna = 1
      }
      const options = {
        curveType: 'function',
        legend: { 
          position: 'top',
          textStyle: {
            fontSize: 14,
            color: '#6E768F',
          },
        },
        height: this.fullHeight ? window.innerHeight * 0.75 : 350,
        hAxis: {
          gridlines: {
            color: '#f0f3f9',
          },
          textStyle: {
            color: '#6E768F',
          },
          viewWindowMode: 'maximized',
          format: this.isDate ? 'dd MMMM, yyyy' : 'auto',//'dd/MM/yyyy',
        },
        vAxis: {
          baselineColor: '#6E768F',
          gridlines: {
            color: '#f0f3f9',
            count: 4
          },
          textPosition: 'in',
          textStyle: {
            color: '#6E768F',
          },
        },
        chartArea: {
          left: 20,
          right: 30,
          top: 40,
          width: "100%",
        },
        tooltip: {
          showColorCode: true,
          text: 'value',
          trigger: 'selection'
        },
        lineWidth: 2,
        pointSize: 5,
        fontSize: '12',
      }
      const chart = new GoogleCharts.api.visualization.LineChart(this.$refs.chart);
      // 
      GoogleCharts.api.visualization.events.addListener(chart, 'ready', () => {
        if(isNumeric(index_fila) || isNumeric(index_columna)){
          chart.setSelection([{row: index_fila, column: index_columna}])
        }
      });
      GoogleCharts.api.visualization.events.addListener(chart, 'select', () => {
        const selection = chart.getSelection()
        let fila = null
        let columna = null
        if(selection.length){
          const item = selection[0]
          if(isNumeric(item.column) && item.column+1 !== index_columna){
            columna = this.rows[0][Math.ceil(item.column/2)].label
          }
          if(isNumeric(item.row)) fila = view.getFormattedValue(item.row, 0)
        }
        this.$emit("onselect", {fila,columna})
      })
      // 
      chart.draw(view, options);
    }
  }
}
</script>