import { clientAxios } from "../../plugins/axios";

const base = `sub/domain`

export const GetService = (payload) => {
  return clientAxios.get(`${base}${payload ? `/${payload}` : ``}`);
}

export const StoreService = (payload = {}) => {
  return clientAxios.post(`${base}`, payload);
}

export const UpdateService = (payload = {}) => {
  return clientAxios.put(`${base}/${payload.subdominio}`, payload);
}

export const DeleteService = (payload) => {
  return clientAxios.delete(`${base}/${payload}`);
}
