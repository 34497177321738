import { GetService,StoreService, DeleteService, UpdateService } from "../services";

export default { 
  async GetRequest({commit}) {
    try {
      const items = await GetService();
      commit("SET_ROWS", items)
      return Promise.resolve(items);
    } catch (e) {
      commit("SET_ROWS", [])
      return Promise.resolve([]);
    }
  },
  async ShowRequest({commit}, payload) {
    try {
      if(!payload){
        commit("SET_ROW")
        return Promise.resolve(null);
      }
      const item = await GetService(payload);
      commit("SET_ROW", item)
      return Promise.resolve(item);
    } catch (e) {
      commit("SET_ROW")
      return Promise.resolve(null);
    }
  },
  async CreateRequest(ctx, payload) {
    try {
      const { url } = await StoreService(payload);
      return Promise.resolve(url);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async UpdateRequest(ctx, payload) {
    try {
      const { url } = await UpdateService(payload);
      return Promise.resolve(url);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async DeleteRequest(ctx, payload) {
    try {
      const {status} = await DeleteService(payload);
      return Promise.resolve(status);
    } catch (e) {
      return Promise.resolve(false);
    }
  },
};
