import BeforeMiddleware from "./middleware/BeforeMiddleware";
// 
const base_path = "cuentas";
const Routes = [
  {
    path: `/`,
    name: "Dashboard",
    component: () =>
    import("./pages/Index.vue"),
    meta: {
      title: "Cuentas",
      beforeEnter: BeforeMiddleware,
    },
  },
  {
    path: `/${base_path}/nuevo`,
    name: `${base_path}-nuevo`,
    component: () => import("./pages/Nuevo.vue"),
    meta: {
      title: "Nueva cuenta",
      beforeEnter: BeforeMiddleware,
    },
  },
];

export default Routes;
