import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCalendar,
  faCloudUploadAlt,
  faGavel,
  faPlus,
  faSearch,
  faSignOutAlt,
  faUser,
  faEye,
  faEyeSlash,
  faSignInAlt,
  faExclamationCircle,
  faCircle,
  faCog,
  faChevronRight,
  faTimesCircle,
  faAngleLeft,
  faAngleRight,
  faUpload,
  faTimes,
  faSortDown,
  faSortUp,
  faClock,
  faShoppingCart,
  faTruck,
  faInbox,
  faCogs,
  faChartLine,
  faGlobe,
  faLock,
  faArrowUp,
  faCaretUp,
  faCaretDown,
  faSave,
  faEdit,
  faEllipsisV,
  faCheckCircle,
  faTrash,
  faChevronLeft,
  faFilter,
  faBroom,
  faFileExcel,
  faFilePdf,
  faFileUpload,
  faList,
  faHome,
  faChartPie,
  faTachometerAlt,
  faDatabase,
  faShareSquare,
  faClone,
  faLink,
  faSpinner,
  faSlidersH,
  faChevronDown,
  faInfo,
  faInfoCircle,
  faExpandAlt,
  faRedo,
  faChevronUp,
  faFileSignature,
  faStar as faStar1,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCcVisa,
  faDropbox,
  faEdge,
} from "@fortawesome/free-brands-svg-icons";
import { faStar as faStar2 } from "@fortawesome/free-regular-svg-icons";

library.add(
  faUser,
  faGavel,
  faCalendar,
  faCloudUploadAlt,
  faPlus,
  faSearch,
  faBars,
  faSignOutAlt,
  faSignInAlt,
  faEye,
  faEyeSlash,
  faExclamationCircle,
  faCircle,
  faCog,
  faChevronRight,
  faChevronLeft,
  faTimesCircle,
  faAngleLeft,
  faAngleRight,
  faUpload,
  faTimes,
  faSortDown,
  faSortUp,
  faClock,
  faGavel,
  faDropbox,
  faShoppingCart,
  faTruck,
  faEdge,
  faCcVisa,
  faInbox,
  faCogs,
  faChartLine,
  faGlobe,
  faLock,
  faArrowUp,
  faCaretUp,
  faCaretDown,
  faSave,
  faEdit,
  faEllipsisV,
  faCheckCircle,
  faTrash,
  faFilter,
  faBroom,
  faFileExcel,
  faFilePdf,
  faFileUpload,
  faList,
  faHome,
  faChartPie,
  faTachometerAlt,
  faDatabase,
  faShareSquare,
  faClone,
  faLink,
  faSpinner,
  faSlidersH,
  faChevronDown,
  faChevronUp,
  faInfo,
  faInfoCircle,
  faExpandAlt,
  faRedo,
  faFileSignature,
  faStar1,
  faStar2,
  faFile,
  faGlobe
);

Vue.component("vue-fontawesome", FontAwesomeIcon);
