"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import parseDataRequest from "../utils/parseDataRequest";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
  baseURL: process.env.VUE_APP_APIURL || "",
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  async function(config) {
    const token_auth = window.localStorage.getItem("token_auth");
    config.headers["Authorization"] = token_auth ? `Bearer ${token_auth}` : ``;
    //
    config.data = await parseDataRequest({ ...config.data })
    if (config.data instanceof FormData) {
      if (config.method.toLowerCase() === "put") {
        config.method = "post"
        config.data.append("_method", "PUT")
      }
    }
    // 
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response.data;
  },
  function(error) {
    // Do something with response error
    if (error && error.response) {
      const status_code = error.response.status;
      if (status_code === 401) {
        store.dispatch("auth/clearAuth")
        store.dispatch("app/setToastError", `Vuelva a ingresar al sistema.`);
      }
      if (status_code === 405) {
        store.dispatch("app/setToastError", `Este servicio no está disponible.`);
      }
      if (status_code === 422) {
        const errors = error.response.data.errors || {};
        let sms = [];
        for (const key in errors) {
          sms = sms.concat(errors[key])
        }
        store.dispatch("app/setToastError", sms.join(`<br>`));
      }
      if (status_code === 500) {
        store.dispatch("app/setToastError", `Hubo un problema interno, vuelva a intentarlo más tarde.`);
      }
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export const clientAxios = _axios;
export default Plugin;
