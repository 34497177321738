export default {
  SET_CSRF(state){
    state.csrf = true
  },
  SET_TOKEN(state, payload = null) {
    state.token = payload;
    if (window.localStorage) {
      window.localStorage.setItem("token_auth", state.token ? state.token : "");
    }
  },
  SET_USER(state, payload = null) {
    state.user = payload;
  },
};
