<template>
  <div>
    <div ref="chart"></div>
    <p v-if="loading" class="p-4">
      <b-skeleton active></b-skeleton>
      <b-skeleton height="120px"></b-skeleton>
    </p>
  </div>
</template>
<script>
import {GoogleCharts} from 'google-charts';
export default {
  props: {
    settings:{
      type: Object,
      default: () => ({})
    },
    results:{
      type: Array,
      default: () => []
    },
    fullHeight: {
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return {
      loading: true,
    }
  },
  computed:{
    rows(){
      return this.results.map((e,i) => {
        if(i === 0){
          e = ""
        }
        if(i === 1){
          e = {v: parseFloat(e), f: ''}
        }
        return e
      }) 
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        if(!this.$refs.chart) {
          this.loading = false
          return;
        }
        GoogleCharts.load(this.drawChart,{
          'packages': ['gauge'],
        })
        this.loading = false
      })
    }, 500)
  },
  methods: {
    drawChart(){
      const {
        minLimit = 0, maxLimit = 100,
        avgSection, maxSection, minSection
      } = this.settings
      //
      const data = GoogleCharts.api.visualization.arrayToDataTable([
        ['Label','Value'],
        ...[this.rows]
      ]);
      
      const options = {
        height: this.fullHeight ? window.innerHeight * 0.75 : 160,
        min: minLimit, max: maxLimit,
        greenColor: minSection.color, greenFrom: minSection.from, greenTo: minSection.to,
        yellowColor: avgSection.color, yellowFrom:avgSection.from, yellowTo: avgSection.to,
        redColor: maxSection.color, redFrom: maxSection.from, redTo: maxSection.to,
        minorTicks: 2,
      }
      const chart = new GoogleCharts.api.visualization.Gauge(this.$refs.chart);
      chart.draw(data, options);
    }
  }
}
</script>