<template>
  <div class="b-table no-border-table">
      <div class="table-wrapper">
        <table class="table is-size-7">
          <thead class="has-background-grey-lighter">
            <tr>
              <td style="width: 40px">
                <div class="th-wrap">
                  <input type="number" v-model="per_page" maxlength="3" style="width: 40px" @keypress.enter.prevent="handlePerPage()" />
                </div>
              </td>
              <td style="width: 35px" v-show="prev_page_url">
                <a href="#" @click.prevent="handlePerPage(prev_page_url)" title="Anterior">
                  <b-icon icon="chevron-left"></b-icon>
                </a>
              </td>
              <td style="width: 130px" v-if="pagination">
                Pág.
                <input
                  type="number"
                  v-model="current_page"
                  style="width: 40px; margin: 0 5px"
                  @keypress.enter.prevent="handleCurrentPage"
                />
                de {{pagination.last_page}}
              </td>
              <td style="width: 35px" v-show="next_page_url">
                <a href="#" @click.prevent="handlePerPage(next_page_url)" title="Siguiente">
                  <b-icon icon="chevron-right"></b-icon>
                </a>
              </td>
              <td class="is-flex is-justify-content-flex-end">
                <div class="th-wrap">Total: {{pagination ? pagination.total : 0}}</div>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
</template>
<script>
import {getQueryUrl,setUriParams} from "@/utils/helpers"
const PER_PAGE = 50;
const CURRENT_PAGE = 1;
export default {
  props:{
    pagination:{
      type: Object,
      default: () => null
    },
    setUrl:{//Seter url params 
      type: Boolean,
      default: () => true,
    },
    filter:{
      type: Object,
      default: () => {},
    }
  },
  data(){
    return {
      per_page: PER_PAGE,
      current_page: CURRENT_PAGE,
    }
  },
  computed:{
    prev_page_url(){
      if(this.pagination){
        return this.pagination.prev_page_url
      }
      return null
    },
    next_page_url(){
      if(this.pagination){
        return this.pagination.next_page_url
      }
      return null
    }
  },
  watch:{
    pagination:{
      handler(e){
        if(e && e.per_page && e.current_page){
          this.per_page = e.per_page
          this.current_page = e.current_page
        } else {
          const {per_page = PER_PAGE, current_page = CURRENT_PAGE} = getQueryUrl()
          this.per_page = per_page
          this.current_page = current_page
        }
        if(this.setUrl){
          this.setUriParams()
        }
      },
      immediate: true,
    }
  },
  methods:{
    getParams(){
      return {
        per_page: this.per_page,
        current_page:this.current_page,
      }
    },
    handleCurrentPage(){
      if(this.current_page !== this.pagination.current_page && this.pagination.links.length > this.current_page){
        const link = this.pagination.links.find(e => e.label === this.current_page)
        if(link && link.url) return this.$emit("load", link.url, this.getParams())
      } else {
        this.current_page = 1
        this.setUriParams()
      }
    },
    handlePerPage(url = null){
      if(isNaN(this.per_page) || Number(this.per_page) < 0){
        this.per_page = PER_PAGE;
        this.setUriParams()
        return;
      }
      this.$emit("load",url, this.getParams())
    },
    setUriParams(){
      setUriParams({
        per_page: this.per_page,
        current_page: this.current_page,
      })
    },
  }
}
</script>