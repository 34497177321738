import { SaveService,GetService,GetConfigService, SaveCamposDinamicosService, DeleteCamposDinamicosService } from "../services";

export default {
  async getRequest({commit, getters}) {
    try {
      if(Object.keys(getters.empresa).length) return Promise.resolve()
      // 
      const data = await GetService();
      commit("SET_NEGOCIO", data);
      return Promise.resolve();
    } catch (e) {
      return Promise.resolve();
    }
  },
  async getConfigRequest({commit, getters}) {
    try {
      if(Object.keys(getters.config).length) return Promise.resolve()
      // 
      const data = await GetConfigService();
      commit("SET_CONFIG", data);
      return Promise.resolve();
    } catch (e) {
      return Promise.resolve();
    }
  },
  async saveRequest({commit}, payload) {
    try {
      const {config, ...data} = await SaveService(payload);
      commit("SET_NEGOCIO", data);
      commit("SET_CONFIG", config);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async saveCamposDinamicosRequest({commit}, payload) {
    try {
      const data = await SaveCamposDinamicosService(payload);
      commit("SET_CAMPOS_DINAMICOS", data)
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
  async deleteCamposDinamicosRequest({commit}, payload) {
    try {
      const data = await DeleteCamposDinamicosService(payload);
      commit("SET_CAMPOS_DINAMICOS", data)
      return Promise.resolve(data);
    } catch (e) {
      return Promise.resolve(null);
    }
  },
};
