import store from "@/store"

export default async (to, from, next) => {
  try {
    const isRoot = store.getters['auth/isRoot']
    if(!isRoot) throw new Error(`Acceso denegado`)
    await store.dispatch("dashboard/ShowRequest", to.query.id)
    next()
  } catch (error) {
    next("/")
  }
}